import React from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from "gatsby"
import PubliclyImg from "../../assets/images/share/publicly-privately.webp"
import PubliclyMImg from "../../assets/images/share/publicity-m.webp"
import ShareMailMImg from "../../assets/images/share/share-mail-m.webp"
import OneClickMImg from "../../assets/images/share/one-click-m.webp"
import MesaggeOutMImg from "../../assets/images/share/message-out-m.webp"
import MultipleAppMImg from "../../assets/images/share/multiple-app-m.webp"

const Share = ({ data }) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="Share | BlueMail App"
        postDescription="Share emails publicly or privately through various social networks & messaging apps."
        postImage="/img/OG/og_image-share.png"
        postURL="features-functions/share"
        postSEO
      />
      <Navbar />
      <div className="features-functions">
        <div className="container-fluid share-mail w-707 r mt-fs">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h1>
                  <span>Share</span> Mail
                </h1>
                <p>
                  Share emails publicly or privately through various social
                  networks & messaging apps, receive emails from people who wish
                  to engage with you instantly while keeping your email address
                  private.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={ShareMailMImg} alt="Share mail" />
            </div>
          </div>
        </div>
        <div className="container-fluid one-click w-707 l">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12">
                <h2>
                  <span>One-Click</span> Share
                </h2>
                <p>
                  Instead of opening the email, pressing the forward option,
                  then typing the recipient’s name, you can just the Share an
                  email at a press of a button.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={OneClickMImg} alt="One click" />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-100 mb-50 text-center">
              <h2>
                <span>Publicly</span> or <span>privately</span>
              </h2>
              <p className="text-center">
                Share lets you share an email publicly or privately, on various
                social networks and messaging apps. Just like that, no stress!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 p-0 mb-100">
              <img
                className="d-992"
                src={PubliclyImg}
                alt="publicly privately"
              />
              <img
                className="w-991 mt-3 mb-5"
                src={PubliclyMImg}
                alt="publicly privately mobile"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid message-out w-707 l">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h2>
                  Making It <span>Simple</span> to Get Your Message Out
                </h2>
                <p>
                  Share Email works instantly, and in these chaotic times,
                  timing can make all the difference. It saves time spent trying
                  to get different apps to work together, taking extra steps,
                  and going through endless choices.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={MesaggeOutMImg} alt="Message out" />
            </div>
          </div>
        </div>
        <div className="container-fluid multiple-app w-707 l mb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12">
                <h2>
                  Integration with <span>multiple</span> apps
                </h2>
                <p>
                  BlueMail’s share integrates with a variety of apps: for
                  example Whatsapp, Telegram or Signal for messaging and
                  Facebook and Twitter for social.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={MultipleAppMImg} alt="Multiple app" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Share

export const query = graphql`
  query SharePageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
